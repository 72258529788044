import React, {useEffect, useState} from "react"
import { Link } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import expired from "../../assets/images/reward/expired@2x.png"
import {useIntl} from "react-intl";
import MediaLayout from "../../components/media-layout";
import {useMediaQuery} from "react-responsive";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};


class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}


const RewardExpired = ({location}) => {
  const [reward, setReward] = useState({});
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : "";
  const intl = useIntl();

  useEffect(() => {
    if (location) {
      if (location.state) {

      }
    }
  },[]);



  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <Link to="/rewards/reward-list" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "reward_shop.coupon.back" })}</Link>
        <div className="white-container text-center">
          <img src={expired} width="73" className="mb-2" alt="Expired"/>
          <h2>{intl.formatMessage({ id: "reward_shop.coupon.voucher_expired" })}</h2>
          <p>{intl.formatMessage({ id: "reward_shop.coupon.download_again" })}</p>
          <div className="mt-4">
            <Link to="/rewards/reward-list" className="button red">{intl.formatMessage({ id: "reward_shop.coupon.access_reward" })}</Link>
          </div>
        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.reward-expired" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default RewardExpired
